@use '../../../utilities/variables' as v;

.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color: v.$black, $alpha: .15);
    display: flex;
    z-index: 1000;
    &.hide{
        display: none;
    }
    .modal-loading{
        margin: auto;
    }
    .modal{
        z-index: 1100;
        margin: auto;
        overflow: hidden;
        border-radius: 16px;
        background: v.$white;
        // width: 30%;
        max-width: 444px;
        padding: 32px;
        white-space: pre-line;
        .modal-body{
            display: flex;
            flex-direction: column;
            gap: 24px;
            position: relative;
            .modal-close{
                position: absolute;
                top: 0;
                right: 0;
                width: 24px;
                height: 24px;
                i{
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                    content: url('../../../utilities/icons/close-black.svg');
                }
            }
            .modal-img{
                width: 100%;
                text-align: center;
            }
            .modal-header{
                color: v.$black;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: center;
                
            }
            .modal-content{
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: v.$black;
                text-align: center;
            }
        }
    }
}
@use "../../utilities/variables" as v;
@import "../../utilities/adaptive";

#home{
    #home-heading{
        background: linear-gradient(0deg, v.$dark_blue 0%, rgba(9, 20, 41, 0.8) 35.38%, rgba(0, 0, 0, 0.4) 100%);
        // background-position: center;
        // background-repeat: no-repeat;
        // background-size: cover;
        height: 100vh;
        max-height: 400px;
        @include _lg{
            max-height: 800px;
        }
        position: relative;
    }
    #home-directions{
        padding: 96px 0;
        background-color: v.$dark_blue;
        
    }
    #home-services{
        padding: 96px 0;
        background-color: v.$white;
    }
    #home-partners{
        background-color: v.$white;
        padding: 96px 0;
        .partners{
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            .partner{
                box-sizing: border-box;
                background-color: #F9F9F9;
                display: flex;
                flex-direction: column;
                gap: 36px;
                border-radius: 24px;
                padding: 36px;
                .partner-body{
                    display: flex;
                    flex-direction: column;
                    gap: 24px;        
                    flex-grow: 1;
                    .partner-img{
                        box-sizing: border-box;
                        padding: 22px;
                        border: 10px solid #FFFFFF;
                        border-radius: 16px;
                        display: block;
                        justify-content: left;
                        align-items: flex-start;
                        img{
                            width: 100%;
                        }
                    }
                    .partner-description{
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 28px;
                        color: v.$dark_grey;
                        span{
                            color: v.$black;
                        }
                    }
                }
                .partner-link{
                    text-decoration: none;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: center;
                    color: v.$ocean_blue;
                    i{
                        content: url('../../utilities/icons/ArrowRight.svg');
                    }
                    &:hover{
                        font-weight: 600;
                    }
                }
            }
            @include _sm{
                flex-direction: row;
                gap: 48px;
                .partner{
                    width: calc(50% - 24px);
                    .partner-body{
                        .partner-img{
                            display: inline-block;
                            img{
                                width: auto;
                                height: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
    #consulting{
        padding: 96px 0;
        background-color: v.$white;
        .col:first-of-type{
            display: flex;
            position: relative;
            .text{
                position: absolute;
                margin: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 1;
                height: 100%;
            }
            .symbols-visual{
                display: flex;
                padding-top: 40px;
                padding-bottom: 48px;
                i{
                    width: 100%;
                    max-width: 100%;
                    margin: auto;
                    content: url("../../utilities/icons/bg-symbols-box.svg");
                }
                @include _lg{
                    margin-left: -40px;
                }
            }
        }
    }
}

.image-container{
    width: 100%;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.background{
    z-index: -10;
}

.slideshow{
    height: 100%;
    width: 100%;
    // position: relative;
    .slideshow-container{
        width: 100%;
        height: 100%;
        .slide{
            width: 100%;
            height: 100%;
        }
    }
    .slideshow-dots{
        z-index: 100;
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .dot{
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: #E9F0FC;
            // opacity: 0.2;
        }
    }
}
@use "../../utilities/variables" as v;
@import "../../utilities/adaptive";

#about{
    #about-heading{
        background-color: v.$dark_blue;
        padding: 96px 0;
        @include _lg{
            padding: 80px 0;
        }
        .row-1{
            gap: 80px;
            @include _lg{
                gap: 0;
            }
        }
        #about-symbol{
            @include _lg{
                margin-right: 26px;
            }
            i{
                display: block;
                aspect-ratio: 1/1;
                width: 100%;
                content: url('../../utilities/icons/about_symbol.svg');
            }
        }
    }
    #about-cards{
        background-color: v.$dark_blue;
        padding: 80px 0;
        .cards{
            gap: 24px;
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: stretch;
            box-sizing: border-box;
            .card{
                box-sizing: border-box;
                width: 100%;
                padding: 20px;
                background: rgba($color: v.$ocean_blue, $alpha: .15);
                border-radius: 24px;
                min-width: 280px;
                .card-title{
                    color: v.$yellow;
                    padding-bottom: 24px;
                }
                .card-text{
                    color: v.$light_grey;
                }
                &:nth-child(2){
                    .card-title{
                        color: v.$white;
                    }   
                    .card-text{
                        color: v.$ocean_blue;
                    }
                }
            }
        }
        @include _md{
            overflow: scroll;
            flex-wrap: nowrap;
            overflow-x: visible;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            .cards{
                gap: 32px;
                flex-direction: row;
                .card{
                    width: 50%;
                    min-width: 530px;
                    padding: 40px;
                    
                }
            }
        }
    }
    #about-staff{
        background-color: v.$light_blue;
        padding: 80px 0;
        .staff-container{
            display: flex;
            flex-direction: column;
            gap: 80px;
            .staff{
                display: flex;
                flex-direction: column;
                gap: 48px;
                @include _lg{
                    gap: 72px;
                    flex-direction: row;
                }

                .staff-image{
                    padding: 72px;
                    position: relative;
                    margin: 0 auto;
                    @include _lg{
                        padding-left: 0;
                        margin: 0;
                    }
                    .bg{
                        border-radius: 0px 96px 96px 0px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 200%;
                        height: 100%;
                        background-color: rgba($color: v.$ocean_blue, $alpha: .15);
                    }
                    .profile-image{
                        position: relative;
                        z-index: 1;
                        width: 320px;
                        height: 320px;
                        overflow: hidden;
                        border-radius: 64px;
                        border-bottom-left-radius: 0;
                        background-color: v.$light_blue;
                        img{
                            width: 100%;
                            aspect-ratio: 1 / 1;
                            border: none;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    
                }
                &:nth-of-type(2){
                    .staff-image{
                        padding: 72px;
                        // padding-right: 0;
                        .bg{
                            border-radius:96px 0 0 96px;
                            left: 0;
                        }
                        .profile-image{
                            border-radius: 64px;
                            border-bottom-right-radius: 0;
                        }
                    }
                    @include _lg{
                        flex-direction: row-reverse;
                        .staff-image{
                            padding-right: 0;
                        }
                    }
                }
                .staff-text{
                    flex-grow: 1;
                    .staff-position{

                    }  
                    .staff-about{

                    }
                    .staff-mail{
                        i{
                            content: url('../../utilities/icons/mail.svg');
                        }
                        a{
                            color: inherit;
                        }
                    }
                }
            }
        }
    }
    #about-certificates{
        background-color: v.$dark_blue;
        padding: 80px 0;
        .images{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 16px;
            @include _lg{
                grid-template-columns: repeat(4, 1fr);
            }
            .image-container{
                &:first-of-type{
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 3;
                }
            }
        }
    }
}
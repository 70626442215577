@use '../utilities/variables' as v;

.textfields-48px{
    label{
        display: block;
        padding-bottom: 8px;
        color: v.$dark_blue;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
    }
    input, .input, select{
        box-sizing: border-box;
        width: 100%;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #D6D9DD;
        background-color: #FFF;
        color: v.$dark_blue;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            background-color: #fff;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        &::placeholder{
            color: #D6D9DD;
        }
    }
    select:invalid { color: #D6D9DD; 
        option{
            color: v.$dark_blue;
        }
    }
}
.checkbox{
    display: flex;
    align-items: center;
    cursor: pointer;
    input[type=checkbox]{
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 0;
        cursor: pointer;
        margin-right: 10px;
        margin-left: 2px;
        width: 20px;
        height: 20px;
        border: 1.5px solid #5E646F;
        border-radius: 4px;
        display: flex;
        &::before{
            content: url('../utilities/icons/checked.svg');
            // transform: translate(-10px, -10px);
            width: 11px;
            height: 8px;
            margin-left: 2.5px;
            margin-top: 1.5px;
            transform: scale(0);
        }
        &:checked::before{
            transform: scale(1);
        }
    }
}

.button-48, .button-48px{
    cursor: pointer;
    height: 48px;
    box-sizing: border-box;
    background-color: #1C6BDB;
    color: white;
    // display: flex;
    display: inline-block;
    // width: 100%;
    padding: 16px 50px;
    white-space: nowrap;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    border: none;
    text-decoration: none;
    &:disabled{
        background-color: #E9F0FC!important;
    }
}
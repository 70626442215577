@import '../utilities/adaptive';


.mt-auto{
    margin-top: auto;
}
.mt-6{
    margin-top: 48px;
}

.mb-2{
    margin-bottom: 16px;
}
.mb-3{
    margin-bottom: 24px;
}
.mb-4{
    margin-bottom: 32px;
}
.mb-5{
    margin-bottom: 40px;
}
.mb-6{
    margin-bottom: 48px;
}
.mb-7{
    margin-bottom: 56px;
}
.mb-8{
    margin-bottom: 64px;
}
.mb-12{
    margin-bottom: 96px;
}
.mv-auto{
    margin-top: auto;
    margin-bottom: auto;
}
.ml-auto{
    margin-left: auto;
}
.m-auto{
    margin: auto;
}


.pos-abs{
    position: absolute;
    top: 0;
    left: 0;
}

.w-30{
    width: 30%;
    max-width: 30%;

}
.w-50{
    width: 50%;
    max-width: 50%;
}
.w-70{
    width: 70%;
    max-width: 70%;
}
.w-100{
    width: 100%;
}
.h-100{
    height: 100%;
}


.mb-lg-12{
    @include _lg{
        margin-bottom: 96px;
    }
}
@use '../../utilities/variables' as v;

#subpage{
    #subpage-top{
        background-color: v.$white;
        padding: 64px 0 96px;
        .icon-container{
            width: 60px;
            height: 60px;
            padding: 30px;
            border-radius: 16px;
            img{
                width: 100%;
                height: 100%;
                aspect-ratio: 1 / 1;
                object-fit: contain;
                object-position: center;
            }
        }
        .description{
            white-space: pre-line
        }
        .carousell{
            width: 100%;
            height: 544px;
            // min-height: 254px;
            // height: auto;
            overflow: hidden;
            border-radius: 40px;
            position: relative;
            transition: opacity 1s ease;
            .carousel-slide{
                display: none;
                opacity: 0;
                width: 100%;
                height: 100%!important;
                img{
                    width: 100%;
                    height: 100%!important;
                    object-fit: cover;
                    object-position: center;
                }

                &.active{
                    display: block;
                    opacity: 1;
                }
                
            }
            #carousel-left, #carousel-right{
                display: block;
                width: 40px;
                height: 40px;
                content: url('../../utilities/icons/bt_arrow_left.svg');
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding: 24px;
                color: white;
                transition: 0.6s ease;
                user-select: none;
            }
            #carousel-right{
                content: url('../../utilities/icons/bt_arrow_right.svg');
                right: 0;
            }
            .dots{
                position: absolute;
                bottom: 21px;
                left: 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 16px;
                .dot{
                    cursor: pointer;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    background-color: v.$dark_blue;     
                    opacity: 0.4;
                    &.active{
                        background-color: v.$ocean_blue;
                        opacity: 1;
                    }
                }
            }
        }
    }
    #siblings{
        padding: 96px 0;
    }
    #consulting{
        padding-top: 106px;
        padding-bottom: 76px;
        background-color: v.$white;
    }

    &.directions{
        #subpage-top{
            .icon-container{
                background-color: rgba($color: v.$ocean_blue, $alpha: .15);
            }
        }
        #siblings{
            background-color: v.$light_blue;
            .icon-cardimage{
                background-color: rgba($color: v.$ocean_blue, $alpha: .15);
            }
        }
    }
    &.services{
        #subpage-top{
            .icon-container{
                background-color: rgba($color: v.$yellow, $alpha: .15);
            }
        }
        #siblings{
            background-color: rgba($color: v.$yellow, $alpha: .15);
            .icon-cardimage{
                background-color: rgba($color: v.$yellow, $alpha: .15);
            }
        }
    }
}
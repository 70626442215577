@use  '../../utilities/variables' as v;

#page404{
    background: v.$white;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    .content{
        height: 100%;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        justify-content: space-between;
        align-items: center;
        .notfound{
            display: flex;
            flex-direction: column;
            gap: 48px;
            white-space: pre-line;
            .text-content{
                display: flex;
                flex-direction: column;
                gap: 16px;
                .title{
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                }
            }
            .button-48{
                max-width: 240px;
                i{
                    content: url('../../utilities/icons/arrow-back.svg');
                }
            }
        }
        #image404{
            width: 50%;
            min-width: 50%;
            max-width: 50%;
            aspect-ratio: 548 / 408;
            background: url('../../utilities/images/page404.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
}
@use '../../../utilities/variables' as v;
@import '../../../utilities/adaptive';

#breadcrumb{
    a{
        text-decoration: none;
        color: inherit;
        &:hover{
            border-bottom: 1px black solid;
        }
    }
    &.theme-white{
        background-color: v.$white;
        .breadcrumbs{
            background-color: #F7F7F7;
            color: v.$dark_grey;
            .breadcrumb{
                a:hover{
                    border-bottom: 1px v.$black solid;
                    color: v.$black;
                }
                &:last-of-type{
                    color: v.$black;
                    font-weight: 600;
                }
            }
        }
    }
    &.theme-dark{
        background-color: v.$dark_blue;
        .breadcrumbs{
            background-color: rgba($color: #F7F7F7, $alpha: .05);
            color: v.$light_grey;
            .breadcrumb{
                a:hover{
                    border-bottom: 1px v.$white solid;
                    color: v.$white;
                }
                &:last-of-type{
                    color: v.$white;
                    font-weight: 600;
                }
            }
        }
    }
    .top{
        width: 100%;
        height: 88px;
        @include _md{
            height: 128px;
        }
    }
    .breadcrumbs{
        padding: 16px 0;
        @include _lg{
            padding: 32px 0;
        }
        .breadcrumbs-content{
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }
    }
}
.skeleton {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
}
  
@keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 70%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
}
  
.skeleton-text {
    min-width: 100px;
    width: 100%;
    height: 1em;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}
  
.skeleton-text__body {
    width: 75%;
    height: 1em;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}
  
.skeleton-footer {
    width: 30%;
}
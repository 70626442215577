@use "../../../utilities/variables" as v;
@import "../../../utilities/adaptive";

#footer{
    background-color: v.$dark_blue;
    color: v.$white;
    padding: 64px 0;
    .content{
        display: flex;
        flex-direction: column;
        gap: 40px;
        .dev{
            font-size: 16px;
            color: v.$white;
            text-decoration: none;
        }
    }

    .brand{
        display: block;
        margin-bottom: 24px;
        .logo{
            content: url('../../../utilities/icons/logo-large.svg');
        }
    }
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .additional-links{
        li{
            font-size: 16px;
            margin-bottom: 16px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .social_media{
        display: flex;
        flex-direction: row;
        gap: 16px;
        margin-bottom: 40px;
        @include _lg{
            margin-bottom: 0;
        }
        .social{
            width: 24px;
            height: 24px;
            display: flex;
            box-sizing: border-box;
            i{
                display: block;
                margin: auto;
            }
        }
    }
    .contacts{
        color: inherit;
        &>*{
            margin-bottom: 16px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        a{
            display: block;
            color: inherit;
            text-decoration: none;
        }
        @include _lg{
            margin-bottom: 24px;
        }
    }
    .sitemap{
        color: inherit;
        display: flex;
        flex-direction: column;
        gap: 16px;
        a{
            display: block;
            color: inherit;
            text-decoration: none;
            font-size: 16px;
        }
    }
    .row-1{
        gap: 40px;
        @include _lg{
            gap: 32px;
        }
    }


}

